<template>
  <div>
    <router-link class="come-back" to="/admin/positions">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование должности' : 'Создание должности' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="addPosition" ref="addPosition">
      <div>
        <div class="content-sb">
          <p>Название должности<span>*</span></p>
          <input required placeholder="Введите название страны" type="text" name="title" v-model="form.title" class="input blue">
        </div>
        <div class="content-sb">
          <p>Название должности (Англ)</p>
          <input placeholder="Введите название страны (Англ)" type="text" name="titleEng" v-model="form.titleEng" class="input blue">
        </div>
        <div class="content-sb">
          <p>Приоритет должности<span>*</span></p>
          <input required type="number" min="0" name="order" v-model="form.order" class="input blue">
        </div>
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/positions" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'createPosition',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailPosition : {
        title: '',
        titleEng: '',
        order: 0
      }
    }
  },
  methods: {
    addPosition () {
      const formData = new FormData(this.$refs.addPosition)
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editPosition', this.form)
      } else {
        this.$store.dispatch('AddPosition', formData)
      }
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailPosition === null) {
      this.$router.push('/admin/positions')
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
